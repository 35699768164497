import styled from 'styled-components';

const ArticleWrapper = styled.div`
  color: #494358;
  padding: 0.85rem;
  padding-right: 1.8rem;
  font-size: 1.1rem;
  @media (min-width: 1200px) {
    font-size: 1rem;
  }
  h1 {
    color: #111;
    border-bottom: 1px solid #ddd;
    padding-bottom: 0.75rem;
    font-size: 2rem;
  }
  h2,h3,h4,h5 {
    color: #222;
  }
  h2 { font-size: 1.8rem; }
  h3 { font-size: 1.5rem; }
  h4 { font-size: 1.2rem; }
  blockquote {
    font-style: italic;
    color: #666;
    padding-left: 1.2rem;
    font-size: 0.95rem;
  }
  p {
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
    text-align: justify;
  }
 
`;

const ArticleMetaWrapper = styled.p`
  color: #999;
  font-size: 0.75rem;
  a.tag {
    text-decoration: none;
    margin-right: 0.6rem;
    :before {
      content: '#';
    }
  }
  line-height: 1rem !important;
`;


export { 
  ArticleWrapper,
  ArticleMetaWrapper
}
