import * as React from 'react';
import { graphql } from 'gatsby';
import Page from '../components/page';
import './../scss/custom.scss';

const PageContainer = ({ data }) => {
  const { frontmatter, slug, body } = data.mdx;
  return (
    <Page frontmatter={frontmatter} slug={slug}>
      {body} 
    </Page>
  );
};

export default PageContainer;

export const query = graphql`
   query ($id: String) {
     mdx(id: {eq: $id}) {
       frontmatter {
         title
         date(formatString: "MMMM Do, YYYY")
         category
         tags
         author
         localImages {
           childImageSharp {
             gatsbyImageData(layout: FULL_WIDTH)
           }
         }
       }
       body
       slug
     }
   }
`;
