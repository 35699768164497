import React from 'react';
import MainLayout from '../../layouts/main';
import LocalImage from '../localImage';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import CodeBlock from '../codeBlock';
import Seo from '../seo';
import { Link } from 'gatsby';
import Youtube from '../widgets/youtube';
import { ArticleWrapper } from './styled';

const Page = ({ children, frontmatter, slug }) => {
  const components = {
    Link,
    Youtube,
    LocalImage,
    pre: CodeBlock
  };

  const {
    title,
    description,
    hero_image,
  } = frontmatter;

  return (
    <MainLayout>
      <Seo title={title} description={description} image={hero_image} article={true} />
      <ArticleWrapper>
        <h1 className="title">{frontmatter.title}</h1>
        <MDXProvider components={components} >
            <MDXRenderer 
              frontmatter={frontmatter}
            >
              {children}
            </MDXRenderer>
        </MDXProvider>
      </ArticleWrapper>
    </MainLayout>
  );
};

export default Page;
